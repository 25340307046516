import * as React from "react";

import { H4, H5 } from "../components/shared/headings";
import { SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import Layout from "../components/shared/layout";
import { Link } from "gatsby";
import Navbar from "../components/navbar";
import { OutboundLink } from "../components/shared/Href";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";
import styled from "styled-components";

const PrivacyAndSecurityPage = () => {
  return (
    <Layout>
        <Seo 
          title={"Privacy and Security | Copilot"}
          description={"When you use Copilot, you are placing your trust in us to handle your data appropriately. That's why we treat your personal and financial data like we'd want ours to be treated."}
        />
        <DownloadModal/>
        <Navbar/>
        <Section>
            <Grid>
                <SecondaryPageTitle>Private and safe, like it should be</SecondaryPageTitle>
                <Subhead>We understand that by using Copilot, you are trusting us with your data. That’s why we treat your personal and financial data like we’d want ours to be treated. Below, you‘ll find the principles that guide our approach to privacy and security.</Subhead>
                <SeriousTalk>
                  <H5>Access</H5>
                  <p>We believe that you should have access to and control over your data. You can download all your transactional data, including categories and notes, from your Copilot app Settings</p>
                  <p>Your personal Copilot financial account data, such as budgets and transactions, is only accessed by the Copilot team when necessary to provide the Copilot services, like when you request support for a data issue. We use aggregated and anonymized data for internal analytics and business purposes – you can read our <Link to="/privacy-policy">Privacy Policy</Link> for more information.</p>
                  <p>We employ a number of security measures to help keep your data safe, including 256-bit encryption to protect it at rest and Transport Layer Security (TLS) to protect it in transit. In other words, your data is encrypted while it is being stored and while interacting with our servers. And we do not see or store your bank login credentials because we partner with trusted data aggregators, like Plaid and Finicity, to connect to your financial institutions. Copilot works with third party vendors who adhere to industry security standards. You can read more about <OutboundLink href="https://plaid.com/security/">Plaid</OutboundLink> and <OutboundLink href="https://www.finicity.com/security/">Finicity&rsquo;s</OutboundLink> security policies on their websites.</p> 
                  <p>Copilot’s direct OAuth integrations for Capital One and Coinbase meet the same data security requirements. OAuth allows us to connect directly with your bank rather than importing your account and transaction data via an aggregator. We do not see or store your OAuth login credentials either.</p>
                  <p>If you choose to integrate Venmo, we do not have direct access to your Venmo account. Instead, we simply process the emails you forward to us. Remember that you can stop forwarding us your emails at any time, or even restrict what emails you decide to forward.</p>
                  <H5>Retention</H5>
                  <p>You can delete your Copilot account at any time from the Settings menu, or by sending a request via in-app chat or to <a href="mailto:privacy@copilot.money">privacy@copilot.money</a>. If you delete your account, we do not keep any of your linked financial data or Copilot account data (email address, budgets, etc.), except in the limited circumstances where required by law, to resolve disputes, protect Copilot and our users, and enforce our agreements. Where Copilot has no such obligations, the data will be completely removed from all our internal systems, including backups, within 60 days.</p>
                  <p><Note>Deleting your Copilot account and canceling your app subscription are two separate actions, as subscriptions are managed by Apple.</Note></p>
                  <ul>
                    <li>You can cancel your app subscription from Settings prior to deleting your account. Go to Copilot Settings <Muted>&#8594;</Muted> Subscription <Muted>&#8594;</Muted> Manage your Subscription and you&rsquo;ll be taken to the App Store to complete the cancelation.</li>
                    <li>If you forget to cancel your subscription before deleting your account, you can still do so by going to your mobile device&rsquo;s Settings <Muted>&#8594;</Muted> Purchases <Muted>&#8594;</Muted> Subscriptions <Muted>&#8594;</Muted> Copilot.</li>
                  </ul>
                  <H5>Infrastructure</H5>
                  <p>Copilot’s infrastructure is built on the Google Cloud Platform (GCP), which is used by leading financial companies worldwide. GCP adheres to industry standard security, privacy and compliance controls, including:</p>
                  <ul>
                    <li>ISO/IEC 27001, 27017 and 27018</li>
                    <li>SOC 1/2/3</li>
                    <li>PCI DSS</li>
                    <li>CSA STAR</li>
                  </ul>
                  <p>Please note that these are GCP certifications and that Copilot is not certified at the moment.</p>
                  <p>We use Multi-Factor Authentication (MFA) on all internal systems and incorporate MFA support and mobile device management into our company devices.</p>
                  <p>We also know that security isn’t a “set it and forget it” sort of thing, which is why we regularly conduct application penetration tests to identify and, as needed, mitigate vulnerabilities or risks in our systems.</p>
                  <H5>What you do in Copilot, stays in Copilot</H5>
                  <p>Our only focus is on building tools that help you improve your finances. We respect your privacy, so we give you transparency and control over your data and keep it private. We don’t like it when we start seeing online ads for things we recently bought, so we do not sell your personal data to third parties so that they can advertise products to you.</p>
                  <H5>Transparency above all else</H5>
                  <p>Our <Link to="/privacy-policy">Privacy Policy</Link> comprehensively details our data practices, but we understand that legal documents aren’t everyone’s favorite thing to read. That’s why we have this page to provide you with a straightforward summary of how we think about your data.</p>
                  <p>We’ll keep this page up-to-date and let you know if anything big changes with our practices. We’re also available if you have any questions or concerns. You can always contact us through in-app chat or at <a href="mailto:privacy@copilot.money">privacy@copilot.money</a>.</p>
                </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

const Subhead = styled(H4)`
    grid-column: col-start / span 12;
    text-align: left;
    max-width: none;
    font-weight: 600;
    font-size: 1.5em;
    ${props => props.theme.device.tablet} {
      margin-bottom: 24px;
      grid-column: 3 / span 8;
      line-height: 1.2;
    }
    ${props => props.theme.device.laptop} {
        margin-bottom: 24px;
        grid-column: 4 / span 6;
    }
    ${props => props.theme.device.laptopLg} {
      margin-bottom: 24px;
    }
`

const Muted = styled.span`
    font-weight: 600;
    opacity: 0.5;
`

const Note = styled.span`
    font-weight: 600;
    color: ${props => props.theme.color.textPrimary};
`

export default PrivacyAndSecurityPage
